import React, { useEffect, useState } from "react";
import _fetch from "../config/api";
import { ReStakeContractAbi, ReStakeContractAddress, StakeContractAbi, StakeContractAddress, WithdrawContractAbi, WithdrawContractAddress, api_url, blackChainUrl, contractAbi, contractAddress, sellContractAbi, sellContractAddress, supportedChainId } from "../config/config";
import dateformat from "dateformat";
import ReactPaginate from "react-paginate";
import card_1 from "../Assets/images/sales-graph-1-1a977117-svg.svg";
import { CheckCircleOutline, Deselect, FiberManualRecord, PersonRounded } from "@mui/icons-material";
import LoadingBar from 'react-top-loading-bar';
import { useParams } from 'react-router-dom';
import { InjectedConnector } from "@web3-react/injected-connector";
import Web3 from 'web3';
import detectEthereumProvider from '@metamask/detect-provider';
import toasted from "../config/toast";
import { Tooltip } from "@mui/material";
import Toggle from "./Components/Toggle";

const StakingRejectWithdraw = (props) => {

  const [searchedValue, setSearchedValue] = useState("");
  const [searchedType, setSearchedType] = useState("username");
  const [tableRecords, settableRecords] = useState([]);
  const [itemOffset, setItemOffset] = useState(1);
  const [allDetail, setAllDetail] = useState({ totalWithdraw: 0,totalRejectWithdraw:0, todayWithdrawAmount: 0, totalApprovedWithdraw: 0, totalPendingWithdraw: 0 });
  const [totalData, setTotalData] = useState(0);
  const [sort, setSort] = useState('created_at');
  const [limit, setLimit] = useState(10);
  const [keyCount, setKeycount] = useState(0);
  const [progress, setProgress] = useState(0);
  const [isTrue, setIsTrue] = useState(true);
  const [type, setType] = useState(false);
  const [text, setText] = useState('');
  const { withdrawtype } = useParams()

  useEffect(() => {
    getData();
  }, [searchedType, sort, searchedValue, itemOffset, limit, withdrawtype]);

  const [selectedIds, setSelectedIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const toggleSelectAll = (selectAll) => {
    const allIds = tableRecords.map((val) => { return { 'id': val?._id, 'amount': val.payable_amount,'coin': val.coin, 'wallet_address': val.wallet_address } });
    // console.log('allIds',allIds);
    setSelectedIds(selectAll ? allIds : []);

  };
  const handleCheckboxChange = (id, payable_amount, wallet_address, isChecked) => {

    if (selectAll) {
      return;
    }
    const obj = { 'id': id, 'coin': payable_amount, 'wallet_address': wallet_address };

    // if (selectedIds?.length == 0) {
    //   setSelectedIds([obj]);
    // } else {
    //   console.log('isChecked', isChecked);
    // if (isChecked) {

    if (selectedIds.filter(selectedId => selectedId.id == id)?.length == 0) {

      setSelectedIds([...selectedIds, obj]);

    } else {
      setSelectedIds(selectedIds.filter(selectedId => selectedId.id != id));
    }
    // }
    // }

  };



  async function getData() {

    setIsTrue(true);
    setProgress(10);

    let searchData = '';
    if (searchedValue !== "" && searchedValue != undefined) {
      searchData = `&search=${searchedType}&value=${searchedValue}`;
    }
    else {
      searchData = "";
    }

    const Data = await _fetch(`${api_url}payout/rejectPayouts?type=nonworking_withdraw_request&sort=${sort}&page=${itemOffset}&limit=${limit}${searchData}`, 'GET', {});
    if (Data?.status === "success") {

      settableRecords(Data?.data?.data);
      setAllDetail(Data);
      setTotalData(Data?.total);
      setProgress(100);
      setIsTrue(false);
    }
    if (itemOffset > 1) {
      setKeycount(0);
      var count = itemOffset - 1;
      setKeycount(count * limit);
    }
  }

  const handlePageClick = async (event) => {
    const newOffset = (event.selected + 1);
    setItemOffset(newOffset);
  };

  /////////////dappppppppp/////////////////////////////////


  ////////////////// dapp things //////////

  const [web3, setWeb3] = useState(null);
  const [account, setAccount] = useState(null);
  



  return (
    <div>

      <LoadingBar
        color='var(--primary-color)'
        progress={progress}
        containerClassName="LoadingBar_contain"
        className="loaderbar-s"
        onLoaderFinished={() => setProgress(0)}
      />

      <div className={isTrue ? 'loader-bg' : ''}></div>

      <div className="table-sec pb-4">
        <div className="table-sec-subheader">

          {/* user details...  */}

          <div className="details-sec">
            <div className="coustom_container">

              <div className="page_info">
                <div className="left__data">
                  <h6> <PersonRounded /> Rejected  Non-Working Withdraw Request</h6>
                  <p>Dashboard / Withdraw / <span>  Rejected  Non-Working Withdraw Request </span></p>
                </div>

                <div className="right_data">
                  <Toggle />
                </div>
              </div>

              <div className="row">
                <div className="col-lg-3 col-sm-6">
                  <div className="card table-details">
                    <div className="card-header">
                      <div className="card-imgs">
                        <img src={card_1} alt="icon-bg" />
                        <PersonRounded className="icon user" />
                      </div>
                      <p className="text-sm mb-0 text-capitalize">
                        Total Payout
                      </p>
                    </div>
                    <div className="text-start pt-1">
                      <h4 className="active-txt"> <span className="de-sc"></span> {Number(allDetail?.totalWithdraw)?.toFixed(4)} TGW</h4>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-6">
                  <div className="card table-details">
                    <div className="card-header">
                      <div className="card-imgs">
                        <img src={card_1} alt="icon-bg" />
                        <PersonRounded className="icon user" />
                      </div>
                      <p className="text-sm mb-0 text-capitalize">
                        Today Payout
                      </p>
                    </div>
                    <div className="text-start bg-info">
                      <h4> <span className="de-sc"></span> {allDetail?.todayWithdrawAmount ? Number(allDetail?.todayWithdrawAmount)?.toFixed(4):'0.0000'} TGW</h4>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-6">
                  <div className="card table-details">
                    <div className="card-header">
                      <div className="card-imgs">
                        <img src={card_1} alt="icon-bg" />
                        <PersonRounded className="icon user" />
                      </div>
                      <p className="text-sm mb-0 text-capitalize">
                        Rejected Payout
                      </p>
                    </div>
                    <div className="text-start bg-success">
                      <h4> <span className="de-sc"></span> {Number(allDetail?.totalRejectWithdraw)?.toFixed(4)} TGW</h4>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-6">
                  <div className="card table-details">
                    <div className="card-header">
                      <div className="card-imgs">
                        <img src={card_1} alt="icon-bg" />
                        <PersonRounded className="icon user" />
                      </div>
                      <p className="text-sm mb-0 text-capitalize">
                        Pending Payout
                      </p>
                    </div>
                    <div className="text-start bg-warning">
                      <h4> <span className="de-sc"></span> {Number(allDetail?.totalPendingWithdraw)?.toFixed(4)} TGW</h4>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div className="coustom_container mb-5">
          <div className="table_main table_main_new">
            <div className="heading heading__new">
              <h1>Rejected Payout List</h1>
              {/* <div className="d-flex align-items-center gap-2"> </div> */}



              <div className="buttons-s__nds pe-2">


                <form action="">
                  <div className="form-group">
                    <select className="form-select" onChange={(e) => setSearchedType(e.target.value)}>
                      <option value={'username'}>Username</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <input
                      className="input-fields"
                      type="search"
                      placeholder="Search"
                      onChange={(e) => setSearchedValue(e.target.value)} />
                  </div>
                </form>
                <div className="btn__sdcsds">


                  <button className="btn btn-success" onClick={() => toggleSelectAll(true)}>
                    <Tooltip title="Select All"> <CheckCircleOutline /> </Tooltip>
                  </button>

                  <button className="btn btn-2 btn-danger ms-2" onClick={() => toggleSelectAll(false)}>
                    <Tooltip title="Unselect All">  <Deselect /> </Tooltip>
                  </button>

                </div>

              </div>
            </div>

            <div className="table-sec-new">
              <div className="outer-table table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Request No.</th>
                      <th scope="col">Username</th>
                      <th scope="col">Coin</th>

                      <th scope="col">Amount</th>
                      <th scope="col">Deduction</th>
                      <th scope="col">Payable Amount</th>
                      <th scope="col">Token Price</th>

                      <th scope="col">Request Date</th>
                      {/* <th scope="col">Remark</th> */}
                      {/* <th scope="col">Paid Date</th> */}
                      <th scope="col">Wallet Address</th>
                      {/* <th scope="col">Txn</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {
                      tableRecords?.map((val, key) => {

                        return (
                          <tr>
                           
                            <td>{val?.request_id}</td>

                            <td>{val?.username}</td>
                            <td>{val?.coin?.toFixed(4)}</td>

                            <td>{val?.amount?.toFixed(4)} TGW</td>
                            <td>{val?.deduction?.toFixed(4)} TGW</td>
                            <td> {val?.payable_amount?.toFixed(4)} TGW</td>
                            <td> ${val?.token_price?.toFixed(4)}</td>

                            <td>{dateformat(val?.created_at, "mmm dd yyyy h:MM:ss TT")}</td>
                            {/* <td>{val?.remark}</td> */}
                            {/* <td>{dateformat(val?.updated_at, "mmm dd yyyy h:MM:ss TT")}</td> */}
                            <td>{val?.wallet_address}</td>
                            {/* <td><a href={blackChainUrl + val?.hash} target="_blank">View TXN </a></td> */}
                          </tr>
                        );
                      })
                    }
                  </tbody>
                </table>
              </div>
              {totalData > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"
                breakLabel="..."
                nextLabel=">"
                onPageChange={handlePageClick}
                pageRangeDisplayed={1}
                pageCount={totalData / 10}
                previousLabel="<"
              />}


            
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StakingRejectWithdraw;
