import React, { useEffect, useRef, useState } from "react";
import { HomeTwoTone, FiberManualRecord, AttachMoneyRounded, PersonRounded, SavingsRounded, AccountBalanceRounded, HistoryEduRounded, PersonAdd, Menu, MenuBook, AddBusiness, AddCard, AccessibilityNew, CurrencyExchange, Inventory, Storefront, AutoGraph, } from "@mui/icons-material";
import chart_blue from "../Assets/images/chart-blue.png";
import card_1 from "../Assets/images/sales-graph-1-1a977117-svg.svg";
import tr_img from "../Assets/images/payout-transaction3.png";
import _fetch from "../config/api";
import { api_url } from "../config/config";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import LoadingBar from 'react-top-loading-bar'
import userIconsvg from "../Assets/images/user.png";
import dateformat from "dateformat";
import Toggle from "./Components/Toggle";
import { Col, Row } from "react-bootstrap";


const OverView = (props: any) => {

  const [progress, setProgress] = useState(0);
  const [isTrue, setIsTrue] = useState(true);
  const [loading, setLoading] = useState(true);
  const [allData, setAllData] = useState({total_package:0, airdrop_reward: 0, booster_airdrop: 0, staking_bonus: 0, referral_airdrop: 0, structure_airdrop: 0, token_income: 0, totalIncome: "", TotalDeposit: "", allUsers: "", activeUsers: "", inactiveUsers: "", blockUsers: "", avaliableIncome: "", AvaliableDeposit: "", directIncome: "", levelIncome: "", club_bonus: 0, roiIncome: "", royaltyIncome: "" });
  const [incomeTrxData, setIncomeTrxData] = useState([]);
  const [newJoinersData, setNewJoinersData] = useState([]);
  const [incomeProfit, setIncomeProfit] = useState({ currentMonthProfit: 0, lastMonthProfit: 0, todayProfit: 0 });

  useEffect(() => {
    getHomeData();
    getDailyIncomeData();
  }, []);

  const getHomeData = async () => {

    setProgress(10);
    setIsTrue(true);
    // setLoading(true);
    let res = await _fetch(`${api_url}home/dashboard`, "GET", {})
    if (res?.status === "success") {
      // setProgress(50);
      setAllData(res?.data);
      setNewJoinersData(res?.data?.newJoinings);
      setIncomeTrxData(res?.data?.incomeTransactions);
      setProgress(100);
      setIsTrue(false);
      // setLoading(false);
    }
  }

  const getDailyIncomeData = async () => {

    let res = await _fetch(`${api_url}home/incomeDetails`, "GET", {})
    if (res?.status === "success") {
      // setProgress(50);
      setIncomeProfit(res.data)
      setIsTrue(false);
      // setLoading(false);
    }
  }

  return (
    <>
      <div className="main_class">

        {/* 
      {loading && (
       <> */}
        <LoadingBar
          color='var(--primary-color)'
          progress={progress}
          containerClassName="LoadingBar_contain"
          className="loaderbar-s"
          onLoaderFinished={() => setProgress(0)}
        />

        <div className={isTrue ? 'loader-bg' : ''}></div>




        <div className="home-sec pb-5">
          <div className="coustom_container">

            <div className="page_info">
              <div className="left__data">

                <h6> <HomeTwoTone /> {props.pageTitle}</h6>
                <p>{new Date().toDateString()}</p>

              </div>

              <div className="right_data">
                <Toggle />
              </div>
            </div>

            <div className="row main-row">


              <Col sm={6} md={4} lg={3}>
                <div className="card kt_card">
                  <div className="icon">
                    <h4>
                      <Inventory />
                    </h4>
                  </div>
                  <h3>Airdrop Reward</h3>

                  <div className="balance">
                    <h4>${Number(allData?.airdrop_reward)?.toFixed(4)}</h4>
                  </div>

                </div>
              </Col>

              <Col sm={6} md={4} lg={3}>
                <div className="card kt_card">
                  <div className="icon">
                    <h4>
                      <AutoGraph />
                    </h4>
                  </div>
                  <h3>Booster Airdrop</h3>

                  <div className="balance">
                    <h4>{Number(allData?.booster_airdrop)?.toFixed(4)} TGW</h4>
                  </div>

                </div>
              </Col>
              <Col sm={6} md={4} lg={3}>
                <div className="card kt_card">
                  <div className="icon">
                    <h4>
                      <AutoGraph />
                    </h4>
                  </div>
                  <h3>Referral Airdrop</h3>

                  <div className="balance">
                    <h4>${Number(allData?.referral_airdrop)?.toFixed(4)}</h4>
                  </div>

                </div>
              </Col>
              <Col sm={6} md={4} lg={3}>
                <div className="card kt_card">
                  <div className="icon">
                    <h4>
                      <AutoGraph />
                    </h4>
                  </div>
                  <h3>Staking Bonus</h3>

                  <div className="balance">
                    <h4>{Number(allData?.staking_bonus)?.toFixed(4)} TGW</h4>
                  </div>

                </div>
              </Col>
              <Col sm={6} md={4} lg={3}>
                <div className="card kt_card">
                  <div className="icon">
                    <h4>
                      <AutoGraph />
                    </h4>
                  </div>
                  <h3>Structure Airdrop</h3>

                  <div className="balance">
                    <h4>${Number(allData?.staking_bonus)?.toFixed(4)}</h4>
                  </div>

                </div>
              </Col>

              <Col sm={6} md={4} lg={3}>
                <div className="card kt_card">
                  <div className="icon">
                    <h4>
                      <AutoGraph />
                    </h4>
                  </div>
                  <h3>Token Income</h3>

                  <div className="balance">
                    <h4>${Number(allData?.token_income)?.toFixed(4)}</h4>
                  </div>

                </div>
              </Col>


              <Col sm={6} md={4} lg={3}>
                <div className="card kt_card">
                  <div className="icon">
                    <h4>
                      <AddCard />
                    </h4>
                  </div>
                  <h3>Total Deposit</h3>

                  <div className="balance">
                    <h4>${Number(allData?.TotalDeposit)?.toFixed(4)}</h4>
                  </div>

                </div>
              </Col>

              <Col sm={6} md={4} lg={3}>
                <div className="card kt_card">
                  <div className="icon">
                    <h4>
                      <AddBusiness />
                    </h4>
                  </div>
                  <h3>Total Payout</h3>

                  <div className="balance">
                    <h4>${Number(allData?.totalIncome)?.toFixed(4)}</h4>
                  </div>

                </div>
              </Col>

              <Col sm={6} md={4} lg={3}>
                <div className="card kt_card">
                  <div className="icon">
                    <h4>
                      <AddBusiness />
                    </h4>
                  </div>
                  <h3>Avaliable Payout</h3>

                  <div className="balance">
                    <h4>${Number(allData?.avaliableIncome)?.toFixed(4)}</h4>
                  </div>

                </div>
              </Col>

              <Col sm={6} md={4} lg={3}>
                <div className="card kt_card">
                  <div className="icon">
                    <h4>
                      <AddBusiness />
                    </h4>
                  </div>
                  <h3>Total Investment</h3>

                  <div className="balance">
                    <h4>${Number(allData?.total_package)?.toFixed(4)}</h4>
                  </div>

                </div>
              </Col>


              <Col sm={6} md={4} lg={3}>
                <div className="card kt_card">
                  <div className="icon">
                    <h4>
                      <AddBusiness />
                    </h4>
                  </div>
                  <h3>Today Profit</h3>

                  <div className="balance">
                    <h4>${Number(incomeProfit?.todayProfit)?.toFixed(4)}</h4>
                  </div>

                </div>
              </Col>
              <Col sm={6} md={4} lg={3}>
                <div className="card kt_card">
                  <div className="icon">
                    <h4>
                      <AddBusiness />
                    </h4>
                  </div>
                  <h3>Current Month Profit</h3>

                  <div className="balance">
                    <h4>${Number(incomeProfit?.currentMonthProfit)?.toFixed(4)}</h4>
                  </div>

                </div>
              </Col>
              <Col sm={6} md={4} lg={3}>
                <div className="card kt_card">
                  <div className="icon">
                    <h4>
                      <AddBusiness />
                    </h4>
                  </div>
                  <h3>Last Month Profit</h3>

                  <div className="balance">
                    <h4>${Number(incomeProfit?.lastMonthProfit)?.toFixed(4)}</h4>
                  </div>

                </div>
              </Col>
            </div>

            <div className="Outer_box">
              <Row>
                <Col lg={6}>
                  <div className="card user_infor">
                    <div className="card-header">
                      <div className="left_ds">
                        <span> <PersonRounded /></span>
                        <h3>All Participants </h3>
                      </div>

                      <h4 className="mb-0">#{allData?.allUsers}</h4>
                    </div>

                    <div className="card-body">
                      <div className="card-data-user">
                        <div className="row">
                          <div className="col-lg-4 col-md-6">
                            <div className="card-sec-1">
                              <p>Active Participants <br /> <span className="bg-success">{allData?.activeUsers ? allData?.activeUsers : "0"}</span></p>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-6">
                            <div className="card-sec-1 center-sd">
                              <p>Inactive Participants <br /> <span className="bg-warning">{allData?.inactiveUsers ? allData?.inactiveUsers : "0"}</span></p>
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-6">
                            <div className="card-sec-1">
                              <p>Block Participants <br /> <span className="bg-danger"> {/* {allData?.blockUsers} */} 0</span></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </Col>

                <Col lg={6}>
                  <div className="add__user">
                    <div className="add_user-header">
                      <PersonAdd className="icon payout" />
                      <h5 className="mb-0 ms-2" >New Members</h5>
                    </div>

                    <div className="add-body">
                      <div className="adduser-tabs-card" >
                        {newJoinersData?.map((item: any, index: any) => {
                          return (
                            <>
                              <div className="tabs-adduser-sec">
                                <div className="tr-left">
                                  <div className="tr-img">
                                    <img src={userIconsvg} alt="img" />
                                  </div>
                                  <div className="tr-text">
                                    <h5>{item?.name}</h5>
                                    <p>{item?.username}</p>
                                  </div>
                                </div>

                                <div className="tr-right">
                                  <p>{dateformat(item?.created_at, "UTC:dd-mm-yyyy hh:mm:ss TT")}</p>
                                </div>
                              </div>
                            </>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>

            <Row className="main-row">

              <div className="col-12">
                <div className="card transactions-card">
                  <div className="card-header">
                    <div className="left_ds">
                      <span> <HistoryEduRounded /></span>
                      <h3>Transactions History </h3>
                    </div>

                  </div>

                  <div className="card-body">
                    <div className="transactions-tabs-card">
                      <table className="table">
                        <tbody>
                          {incomeTrxData?.map((item: any, index: any) => {
                            return (
                              <>


                                <tr>
                                  <td>
                                    <div className="tr-img">
                                      <img src={tr_img} alt="tr-img" />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="tr-text">
                                      {/* <h5>Payout</h5> */}
                                      <p>{item?.username}</p>
                                    </div>
                                  </td>
                                  <td className="blanace">${item?.amount?.toFixed(4)}</td>
                                  {/* <td> <span className="badge-span debit">Debit</span> </td> */}
                                  {/* <td className="badge-status"> <span className="badge-span credit">{item?.description}</span> </td> */}
                                  <td className="date">{item?.description}</td>
                                  <td className="date">{dateformat(item?.created_at, "UTC:dd-mm-yyyy hh:mm:ss TT")}</td>
                                </tr>
                              </>
                            )
                          })}

                        </tbody>
                      </table>
                    </div>
                  </div>


                </div>
              </div>
            </Row>
          </div>
        </div>

      </div>
    </>
  );
};

export default OverView;
